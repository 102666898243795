import { render, staticRenderFns } from "./Binding.vue?vue&type=template&id=abde1ad4&scoped=true"
import script from "./Binding.vue?vue&type=script&lang=js"
export * from "./Binding.vue?vue&type=script&lang=js"
import style0 from "./Binding.vue?vue&type=style&index=0&id=abde1ad4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abde1ad4",
  null
  
)

export default component.exports