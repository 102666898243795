<template>
    <div class="binding-div">
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="账号和绑定设置"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div>
            <div class="binding-list binding-list-top" @click="replaceClick">
                <div class="binding-list-left">
                    <p v-if="user_infoList.phone"><img src="../../assets/images/account_sel1.png" alt="" class="phone1">
                    </p>
                    <p v-else><img src="../../assets/images/phone1.png" alt="" class="phone1"></p>
                    <p class="binding-title">手机号设置</p>
                    <p class="phone" v-if="user_infoList.phone">（{{user_infoList.phone}}）</p>
                </div>
                <div class="binding-list-left">
                    <p class="binding-subtitle" v-if="user_infoList.phone">已绑定</p>
                    <p class="binding-subtitle" v-else>去绑定</p>

                    <van-icon name="arrow" color="#BFC2CC"/>

                </div>
            </div>
            <!--            <p class="binding-text">其他登录方式</p>-->
            <!--            <div class="binding-list border">-->
            <!--                <div class="binding-list-left">-->
            <!--                    <p v-if="get_bindList.bind_weixin == 1"><img src="../../assets/images/account_sel2.png" alt=""-->
            <!--                                                                 class="phone2"></p>-->
            <!--                    <p v-else><img src="../../assets/images/phone2.png" alt="" class="phone2"></p>-->
            <!--                    <p class="binding-title">微信</p>-->
            <!--                </div>-->
            <!--                <div class="binding-list-left">-->
            <!--                    <p class="weixin-border active" v-if="get_bindList.bind_weixin == 1" @click="hasWX">-->
            <!--                        <img src="../../assets/images/account_sel4.png" alt="" class="phone4"> 已绑定-->
            <!--                    </p>-->
            <!--                    <p class="weixin-border " v-else @click="ToWX">-->
            <!--                        <img src="../../assets/images/phone4.png" alt="" class="phone4"> 去绑定-->
            <!--                    </p>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div class="binding-list">-->
            <!--                <div class="binding-list-left">-->
            <!--                    <p v-if="get_bindList.bind_qq == 1"><img src="../../assets/images/account_sel3.png" alt=""-->
            <!--                                                             class="phone3"></p>-->
            <!--                    <p v-else><img src="../../assets/images/phone3.png" alt="" class="phone3"></p>-->
            <!--                    <p class="binding-title">QQ</p>-->
            <!--                </div>-->
            <!--                <div class="binding-list-left">-->
            <!--                    <p class="weixin-border active" v-if="get_bindList.bind_qq == 1" @click="hasQQ">-->
            <!--                        <img src="../../assets/images/account_sel4.png" alt="" class="phone4"> 已绑定-->
            <!--                    </p>-->
            <!--                    <p class="weixin-border" v-else  @click="ToQQ">-->
            <!--                        <img src="../../assets/images/phone4.png" alt="" class="phone4"> 去绑定-->
            <!--                    </p>-->
            <!--                </div>-->
            <!--            </div>-->

            <!--            <div class="up-login">-->
            <!--                注销账号-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "Binding",
        data() {
            return {
                user_infoList: [],
                get_bindList: [],
            }
        },
        created() {
            this.user_info()
            this.get_bind()
        },
        methods: {

            ToWX() {
                this.$toast('绑定微信登录')
            },
            ToQQ() {
                this.$toast('绑定QQ登录')
            },

            hasWX() {
                this.$dialog.alert({
                    title: '解除微信绑定',
                    message: '确定要解除微信绑定嘛？一旦解除将不能再微信登录',
                    showCancelButton: 'true',
                    confirmButtonText: '解除',
                    confirmButtonColor: '#4D77FD',
                    cancelButtonColor: '#606266',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.$axios.post('/api/unbind_third', {
                        type: 'weixin',
                    })
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.get_bind()
                            }
                        })
                })
            },
            hasQQ() {
                this.$dialog.alert({
                    title: '解除QQ绑定',
                    message: '确定要解除QQ绑定嘛？一旦解除将不能再QQ登录',
                    showCancelButton: 'true',
                    confirmButtonText: '解除',
                    confirmButtonColor: '#4D77FD',
                    cancelButtonColor: '#606266',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.$axios.post('/api/unbind_third', {
                        type: 'qq',
                    })
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.get_bind()
                            }
                        })
                })
            },
            user_info() {
                this.$axios.get('/api/user_info')
                    .then(res => {
                        var res = res.data.data
                        var tel = res.phone;
                        tel = "" + tel;
                        if(res.phone){
                            res.phone = tel.replace(tel.substring(3, 7), "****")
                        }

                        this.user_infoList = res
                    })
            },
            get_bind() {
                this.$axios.post('/api/get_bind_info')
                    .then(res => {
                        var res = res.data.data
                        this.get_bindList = res
                    })
            },
            replaceClick() {
                if (this.user_infoList.phone) {
                    this.$router.push({
                        path: '/replace' //查看绑定的手机号
                    })
                } else {
                    this.$router.push({
                        path: '/replacephone' //绑定手机号
                    })
                }
            },
        }
    }
</script>

<style scoped>
    .active {
        border: 1px solid #BFC2CC !important;
        color: #BFC2CC !important;
    }

    .binding-title {
        font-size: 28px;
        color: #0A1C33;
    }

    .phone {
        color: #909399;
        font-size: 24px;
    }

    .up-login {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #D53D3C;
        font-size: 26px;
        background: #fff;
        margin-top: 50px;
    }

    .border {
        border-bottom: 1px solid #F2F5F8;
    }

    .phone4 {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }

    .phone2 {
        width: 29px;
        height: 23px;
        margin-right: 15px;
    }

    .phone3 {
        width: 22px;
        height: 24px;
        margin-right: 15px;
    }

    .weixin-border {
        border: 1px solid #4D77FD;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4D77FD;
        font-size: 24px;
        padding: 10px 20px;
        border-radius: 30px;
    }

    .binding-text {
        color: #909399;
        font-size: 24px;
        padding: 40px;
    }

    .binding-list-top {
        margin-top: 20px;
    }

    .binding-subtitle {
        color: #909399;
        font-size: 26px;
    }

    .phone1 {
        width: 20px;
        height: 26px;
        margin-right: 15px;
    }

    .binding-list-left {
        display: flex;
        align-items: center;
    }

    .binding-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        background: #fff;
        padding: 0 40px;
    }

    .blue {
        color: #4D77FD;
        font-size: 30px;
    }

    .binding-div {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #f1f6f7;
    }
</style>